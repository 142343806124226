import { render, staticRenderFns } from "./xp.vue?vue&type=template&id=779a1b72"
import script from "./xp.vue?vue&type=script&lang=js"
export * from "./xp.vue?vue&type=script&lang=js"
import style0 from "./xp.vue?vue&type=style&index=0&id=779a1b72&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QCheckbox,QBtnToggle});
